<template>
  <ContentWrapper v-loading="loading">
    <div class="d-flex justify-content-between">
      <div style="max-width: calc(100% - 90px)">
        <h5>
          <b style="display: block; overflow-x: hidden;">{{ type === 'create' ? $t('system.create') : headerTitle }}</b>
        </h5>
      </div>
      <FormGenerator
          :backend_errors="backend_errors"
          :data="data"
          :dictionaries="dictionaries"
          :inputRequiredEmpty="inputRequiredEmpty"
          :mode="type"
          :schema="schema.menu"
          :schemaVersion="schemaVersion"
          v-if="schema && schema.menu"
      >
        <template slot="staticScope">
          <div
              class="additional-icons"
              v-if="type === 'read'"
          >
            <i
                @click="remove"
                v-if="ifHasPermission([`${overlayModuleName.toLowerCase()}-${overlaySubmoduleName.toLowerCase()}-delete`])"
            >
              <font-awesome-icon icon="trash-alt" />
            </i>
            <i
                @click="edit"
                v-if="ifHasPermission([`${overlayModuleName.toLowerCase()}-${overlaySubmoduleName.toLowerCase()}-edit`])"
            >
              <font-awesome-icon icon="edit" />
            </i>
            <!--            <i @click="modalEntityId = 'create'">-->
            <!--              <font-awesome-icon icon="plus"/>-->
            <!--            </i>-->
          </div>
        </template>
      </FormGenerator>
    </div>
    <hr class="mt-2">
    <div
        class="container-fluid"
        style="padding-bottom: 1px;"
    >
      <el-form>
        <div>
          <FormGenerator
              :backend_errors="backend_errors"
              :data="data"
              :dictionaries="dictionaries"
              :inputRequiredEmpty="inputRequiredEmpty"
              :mode="type"
              :schema="schema.containers"
              :schemaVersion="schemaVersion"
              v-if="schema && schema.containers"
          >
          </FormGenerator>
          <div
              class="row"
              v-if="type !== 'read'"
          >
            <div
                :class="schema && schema.containers && schema.containers[schema.containers.length - 1] && schema.containers[schema.containers.length - 1].class ? schema.containers[schema.containers.length - 1].class : ''"
                class="ml-auto"
            >
              <div class="d-flex justify-content-end">
                <el-button
                    :loading="saveOrUpdateLoading"
                    @click="saveOrUpdate"
                    size="mini"
                    type="primary"
                >
                  {{ type === 'create' ? $t('system.create') : $t('system.update') }}
                </el-button>
                <!--                <el-button v-if="type === 'edit'" @click.native="show">{{ $t('system.cancel') }}</el-button>-->
                <el-button
                    @click="cancel"
                    size="mini"
                >{{ $t('system.cancel') }}
                </el-button>
              </div>
            </div>
          </div>
        </div>
      </el-form>
    </div>
    <!--    <ModalGeneratorWrapper-->
    <!--      v-model="modalEntityId"-->
    <!--      :formId="overlayEntityFormId"-->
    <!--      :moduleName="overlayModuleName"-->
    <!--      :subModuleName="overlaySubmoduleName"-->
    <!--    />-->
  </ContentWrapper>
</template>

<script>
import axios from 'axios';
import swal from 'sweetalert2';
import FormGenerator from '../../crm-form-generator/FormsGenerator.vue';
import ContentWrapper from '../../HOCs/ContentWrapper.vue';
import {getObjectValueByPath} from '../../../helpers/functions';
import notification from '../../../notification/notify';
import EventBus from '../../../eventBus';

export default {
  name: 'MainComponent',
  components: {
    // ModalGeneratorWrapper: () => import('../../dialog/ModalGeneratorWrapper.vue'),
    FormGenerator,
    ContentWrapper,
  },
  props: {
    headerTitlePath: {
      type: String,
      default: 'base.name',
    },
    overlaySubmoduleName: {
      type: String,
      required: true,
    },
    overlayModuleName: {
      type: String,
      required: true,
    },
    overlayEntityId: {
      type: [Number, String],
      validator: value => {
        if (typeof value === 'string') {
          return value === 'create';
        }
        return true;
      },
    },
    overlayEntityFormId: {
      type: Number,
    },
  },
  data() {
    return {
      // relationships: [
      //   {label: 'general'}
      // ],
      data: {},
      // activeTab: 'general',
      type: 'read',
      schema: null,
      schemaVersion: null,
      // tabs: {},
      loading: true,
      saveOrUpdateLoading: false,
      backend_errors: {},
      inputRequiredEmpty: [],
      dictionaries: {},
      // componentKey: 0,
      headerTitle: '',
      // modalEntityId: null,
      oldFormData: {
        base: {},
        custom: {},
      },
    };
  },
  // computed: {
  // modalName() {
  //   return `modalGenerator_${Math.floor(Math.random() * 100000)}`
  // },
  // currentRelationship() {
  //   return this.relationships.find(item => item.label === this.activeTab)
  // }
  // },
  beforeMount() {
    if (this.overlayEntityId === 'create') {
      this.create();
    } else {
      this.show();
    }
  },
  methods: {
    async fetchData(type = 'read', formId, entityId) {
      this.type = type;
      this.loading = true;
      this.backend_errors = {};
      let data = null;
      try {
        if (type === 'create') {
          data = await axios.get(`/${this.$route.params.moduleName || 'crm'}/${this.overlaySubmoduleName}/create?form_id=${formId}`);
        } else if (type === 'read' && entityId) {
          data = await axios.get(`/${this.$route.params.moduleName || 'crm'}/${this.overlaySubmoduleName}/${entityId}?form_id=${formId}`);
        } else if (type === 'edit') {
          data = await axios.get(`/${this.$route.params.moduleName || 'crm'}/${this.overlaySubmoduleName}/${entityId}/edit?form_id=${formId}`);
        }
        this.type = type;
        data = data.data ? data.data : null;
        if (data && data.data && data.dictionaries && data.schema) {
          this.data = data.data;
          this.oldFormData.base = data && data.data && data.data.base ? {...data.data.base} : {};
          this.oldFormData.custom = data && data.data && data.data.custom ? {...data.data.custom} : {};
          this.dictionaries = data.dictionaries;
          this.schema = data.schema;
          this.schemaVersion = data.schemaVersion;
          this.tabs = data.relationships;
          // this.componentKey++;
          this.headerTitle = this.getHeaderTitle();
          // this.loading = false;
        }
      } catch (e) {
        notification.notify(
            this.$t('notify.error'),
            this.$t('system.can_not_load'),
            'error');
      } finally {
        this.loading = false;
      }
      // this.loading = false;
    },
    cancel() {
      if (this.type === 'create') {
        this.$router.push({
          name: 'ModuleIndex',
          params: {
            moduleName: this.overlayModuleName,
            subModuleName: this.overlaySubmoduleName,
          },
        });
      } else {
        this.show();
      }
    },
    async show() {
      await this.fetchData('read', this.overlayEntityFormId, this.overlayEntityId);
    },
    async create() {
      await this.fetchData('create', this.overlayEntityFormId, this.overlayEntityId);
    },
    async edit() {
      await this.fetchData('edit', this.overlayEntityFormId, this.overlayEntityId);
    },
    async remove() {
      // const swal = await import('sweetalert2');
      // console.log('swal', swal)
      const result = await swal.fire({
        target: this.$refs.root,
        title: this.$t('system.remove'),
        text: this.$t('system.if_you_sure'),
        type: 'info',
        showCancelButton: true,
        focusCancel: true,
        confirmButtonText: this.$t('system.yes'),
        cancelButtonText: this.$t('system.no'),
      });
      if (result.value) {
        try {
          await axios.delete(`${this.$route.params.moduleName || 'crm'}/${this.overlaySubmoduleName}/${this.overlayEntityId}`);
          notification.notify(
              this.$t('notify.success'),
              this.$t(`system.${this.overlaySubmoduleName}_deleted`),
              'success');
          await this.$router.replace({
            name: 'ModuleIndex',
            params: {
              moduleName: this.overlayModuleName,
              subModuleName: this.overlaySubmoduleName,
            },
          });
        } catch (e) {
          notification.notify(
              this.$t('notify.error'),
              this.$t('system.can_not_load'),
              'error');
        }
      }
    },
    getHeaderTitle() {
      return `${getObjectValueByPath(this.headerTitlePath, this.data) ? getObjectValueByPath(this.headerTitlePath, this.data) : ''}`;
    },
    getFileUploadComponent() {
      let result = null;
      this.schema.containers.forEach((column) => {
        column.fields.forEach((item) => {
          if (item.inputType === 'files') {
            result = item;
          }
        });
      });
      return result;
    },
    saveOrUpdate() {
      const fileComponent = this.getFileUploadComponent();
      const requiredItemsNames = [];
      if (fileComponent && fileComponent.validation && fileComponent.validation.includes('required')) {
        const valueFromBase = getObjectValueByPath(`base.${fileComponent.dbName}`, this.data);
        const valueFromCustom = getObjectValueByPath(`custom.${fileComponent.dbName}`, this.data);
        if (valueFromBase || valueFromCustom) {
          if ((valueFromBase && !valueFromBase.length) || (valueFromCustom && !valueFromCustom.length)) {
            requiredItemsNames.push(fileComponent.dbName);
          }
        }
      }
      this.checkValidation(requiredItemsNames).then(async () => {
        this.saveOrUpdateLoading = true;
        const oldData = {...this.oldFormData.custom, ...this.oldFormData.base};
        const dataToSend = this.parseFields({...this.data.custom, ...this.data.base});
        Object.keys(dataToSend).forEach(key => {
          if (dataToSend[key] === oldData[key] || (this.existsFileUploadComponent() && key === this.overlaySubmoduleName)) {
            delete dataToSend[key];
          }
        });
        const params = {
          ...dataToSend,
          form_id: this.overlayEntityFormId,
        };
        try {
          const {data} = await axios.post(`${this.$route.params.moduleName || 'crm'}/${this.overlaySubmoduleName}${this.overlayEntityId === 'create' ? '' : `/${this.overlayEntityId}`}`, params);
          if (this.existsFileUploadComponent()) {
            EventBus.$emit('WAS_CREATED_OR_UPDATED', this.overlayEntityId === 'create' ? data.id : this.overlayEntityId);
            const handleFileUpload = new Promise((resolve, reject) => {
              EventBus.$on('FILES_UPLOADED', () => {
                resolve();
              });
            });
            await handleFileUpload;
          }
          notification.notify(
              this.$t('notify.success'),
              this.$t(`system.${this.overlaySubmoduleName}_${this.overlayEntityId === 'create' ? 'saved' : 'updated'}`),
              'success');
          if (this.overlayEntityId === 'create') {
            await this.$router.push({
              name: 'ModuleEntity',
              params: {
                moduleName: this.overlayModuleName,
                subModuleName: this.overlaySubmoduleName,
                formId: this.overlayEntityFormId,
                entityId: this.overlayEntityId === 'create' ? data?.id : this.overlayEntityId,
              },
            });
          } else {
            await this.show();
          }
        } catch (e) {
          this.saveOrUpdateLoading = false;
          if (e && e.response && e.response.data && e.response.data.errors) {
            this.backend_errors = e.response.data.errors;
          } else {
            console.log('error', e);
          }
        } finally {
          this.saveOrUpdateLoading = false;
        }
      })
          .finally(() => {
            this.saveOrUpdateLoading = false;
          });
    },
    parseFields(data) {
      const newData = {...data};
      Object.keys(this.schema).forEach(schemaPartName => {
        Object.keys(this.schema[schemaPartName]).forEach(container => {
          if (this.schema[schemaPartName][container].fields) {
            this.schema[schemaPartName][container].fields.forEach(field => {
              if (field.validation && field.validation.includes('numeric') && data[field.dbName]) {
                newData[field.dbName] = String(data[field.dbName]).replace(/,/gi, '.');
              } else if (field.inputType === 'checkbox' || field.type === 'switch') {
                newData[field.dbName] = !!data[field.dbName];
              }
            });
          }
        });
      });
      return newData;
    },
    existsFileUploadComponent() {
      let result = false;
      this.schema.containers.forEach((column) => {
        column.fields.forEach((item) => {
          if (item.inputType === 'files') {
            result = true;
          }
        });
      });
      return result;
    },
  },
};
</script>

<style
    lang="scss"
    scoped
>

</style>
